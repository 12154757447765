<template>
  <v-card min-width="600" flat dense>
    <v-card-title>
      {{ dir.name }}
      <v-spacer />
      <v-btn icon @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <shared-documents-list :documents="documents" hide-default-footer />
    </v-card-text>
  </v-card>
</template>

<script>
import { PersonsService } from '@/services/persons.service';
import SharedDocumentsList from '@/modules/persons/components/SharedDocuments/SharedDocumentsList.vue';
export default {
  name: 'ViewSharedDocumentsDir',

  components: {
    SharedDocumentsList,
  },

  props: {
    personId: {
      type: Number,
      required: true,
    },
    dirId: {
      type: Number,
      required: true,
    },
    dir: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      documents: [],
    };
  },

  mounted() {
    PersonsService.getSharedDocumentsByDir({
      personId: this.personId,
      dirId: this.dirId,
    }).then(({ data }) => {
      this.documents = data.data;
    });
  },

  methods: {
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
