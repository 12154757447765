<template>
  <v-container fluid>
    <v-toolbar>
      <v-toolbar-title>{{ fullName }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn dark color="indigo" @click="$router.go(-1)">
        {{ $t('BUTTON.BACK') }}
      </v-btn>
    </v-toolbar>

    <v-row class="mt-4">
      <v-col cols="12">
        <main-information-card v-model="person" />

        <access-app v-model="person.user" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <apartments
          v-model="person.inhabitant"
          :person="person"
          :disabled="person.user == null"
          @update="update"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <shared-documents-card :person-id="id" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Apartments from '@/modules/persons/components/InhabitantCard.vue';
import MainInformationCard from '@/modules/persons/components/MainInformationCard';
import SharedDocumentsCard from '@/modules/persons/components/SharedDocumentsCard.vue';

import { mapActions } from 'vuex';
import AccessApp from './AccessAppCard.vue';

export default {
  name: 'PersonShowPage',

  components: {
    MainInformationCard,
    AccessApp,
    Apartments,
    SharedDocumentsCard,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    person: {
      user: {},
      inhabitant: {},
    },
  }),

  computed: {
    fullName() {
      return `${this.person.first_name} ${this.person.last_name}`;
    },
  },

  created() {
    this.setData();
  },

  methods: {
    ...mapActions('person', ['getPerson']),

    update() {
      this.setData();
    },

    setData() {
      this.getPerson({ personId: this.id }).then(({ data }) => {
        this.person = data.data;
      });
    },
  },
};
</script>
