<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="documents"
      :server-items-length="meta.total"
      :footer-props="footerProps"
      :options="options"
      :page="page"
      :items-per-page="itemsPerPage"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :multi-sort="multiSort"
      :hide-default-footer="hideDefaultFooter"
      v-on="listeners"
    >
      <template v-slot:item.original_name="{ item }">
        <a target="_blank" :href="item.url" style="cursor: pointer">{{
          item.original_name
        }}</a>
      </template>

      <template v-slot:item.size="{ item }">
        {{ Math.round(item.size / 1000) }}
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | dateTimeFormat }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { isEqual } from 'lodash';
import { dateTimeFormat } from '@/filters';

export default {
  name: 'SharedDocumentsDirList',

  filters: {
    dateTimeFormat,
  },

  props: {
    documents: {
      type: Array,
      required: true,
    },
    meta: {
      type: Object,
      default: () => ({
        total: -1,
      }),
    },
    options: {
      type: Object,
      default: () => {},
    },
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    sortBy: {
      type: [String, Array],
      default: 'id',
    },
    sortDesc: {
      type: [Boolean, Array],
      default: false,
    },
    multiSort: {
      type: Boolean,
      default: false,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    footerProps: {
      itemsPerPageOptions: [10, 50, 100],
    },
  }),

  computed: {
    listeners: function () {
      return Object.assign({}, this.$listeners, {
        'update:options': this.updateOptions,
      });
    },

    headers() {
      return [
        {
          text: this.$t('ITEM.ID'),
          align: 'left',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('SHARED_DOCUMENTS.TITLE'),
          sortable: true,
          value: 'original_name',
        },
        {
          text: this.$t('SHARED_DOCUMENTS.SIZE'),
          sortable: true,
          value: 'size',
        },
        {
          text: this.$t('SHARED_DOCUMENTS.DATE_ADDED'),
          sortable: true,
          value: 'created_at',
        },
      ];
    },
  },

  methods: {
    updateOptions(options) {
      if (isEqual(options, this.options)) {
        return;
      }

      this.$emit('update:options', options);
    },
  },
};
</script>
