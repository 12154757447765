<template>
  <v-card tile>
    <v-card-title class="font-weight-regular">
      {{ $t('PERSONS.MAIN_INFORMATION') }}
      <v-spacer></v-spacer>
      <div>
        <span class="text-body-2">{{ $t('ITEM.STATUS') }}:</span>
      </div>
      <div>
        <v-select
          v-model="form.status_id"
          solo
          flat
          :items="statuses"
          item-value="id"
          item-text="name"
          small-chips
          hide-details
          dense
          :disabled="!editMode"
          style="width: 130px"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              small
              :color="item.color"
              dark
              class="ml-1"
              v-bind="attrs"
              :input-value="selected"
              @click="select"
            >
              {{ item.name }}
            </v-chip>
          </template>
        </v-select>
      </div>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-form ref="mainInformationForm" class="px-4">
        <v-row>
          <v-col>
            <person-form
              v-model="form"
              :readonly="!editMode"
              :errors="errors.get('person')"
              @update-prop="clearErrorByName($event, 'person')"
            />
            <personal-profile-form
              v-model="form.personal_profile"
              :person="form"
              :readonly="!editMode"
              :errors="errors.get('personal_profile')"
              @update-prop="clearErrorByName($event, 'personal_profile')"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mask } from 'vue-the-mask';
import { isEqual, cloneDeep } from 'lodash';
import { dateFormat } from '@/filters';
import { LanguagesService } from '@/services/languages.service';
import PersonalProfileForm from '@/modules/persons/components/forms/MainInformation/PersonalProfileForm';
import PersonForm from '@/modules/persons/components/forms/MainInformation/PersonForm';
import errors from '@/mixins/errors';
import i18nRefreshData from '@/mixins/i18nRefreshData';
import { mapCacheActions } from 'vuex-cache';

export default {
  name: 'MainInformationCard',

  components: { PersonalProfileForm, PersonForm },

  directives: {
    mask,
  },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  mixins: [errors, i18nRefreshData],

  filters: {
    dateFormat,
  },

  data: () => {
    return {
      form: {
        salutation: '',
        first_name: '',
        last_name: '',
        gender: '',
        language_id: 1,
        personal_profile: {
          marital_status: '',
          mobile_phone: '',
          phone: '',
          street: '',
          zip: '',
          city: '',
          birthday: '',
        },
      },
      languages: [],

      editMode: false,
      statuses: [],
    };
  },

  watch: {
    editMode: {
      deep: true,
      immediate: true,
      handler: function (v) {
        if (v) {
          this.formCopy = cloneDeep(this.form);
        }
      },
    },

    value: {
      deep: true,
      immediate: true,
      handler: function (formProps) {
        if (isEqual(formProps, this.form)) {
          return;
        }

        this.form = {
          ...this.form,
          ...formProps,
        };

        this.emitter();
      },
    },
  },

  computed: {},

  methods: {
    ...mapCacheActions('person', ['getStatuses']),

    emitter() {
      this.$emit('input', this.form);
    },
    refreshData() {
      this.getStatuses().then(({ data }) => {
        this.statuses = data;
      });
    },
  },

  created() {
    this.refreshData();

    LanguagesService.getList().then(({ data }) => {
      this.languages = data;
    });
  },
};
</script>
