<template>
  <v-card class="checkout-sheet" flat dense>
    <v-card-title v-if="!!Object.keys(form).length">
      <div class="d-inline-flex align-baseline">
        <span class="text-h5">{{ $t('INHABITANT_CHANGES.NEW_REQUEST') }}</span>
      </div>
      <v-spacer></v-spacer>
      <v-chip color="error" small class="ma-5">
        {{ $t('ITEM.NEW') }}
      </v-chip>
      <v-btn icon @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text dense>
      <checkout-info :person="person" :apartment="form.apartment" />
      <v-form ref="form" v-model="isValid">
        <checkout
          v-model="form"
          :errors="errors.inhabitant"
          @update-prop="updateValue(`inhabitant.${$event}`, value)"
        >
        </checkout>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <slot name="buttons" />
    </v-card-actions>
  </v-card>
</template>

<script>
import Checkout from '@/modules/inhabitant_changes/components/forms/Checkout';
import CheckoutInfo from '@/modules/inhabitant_changes/components/forms/CheckoutInfo';
import formEditable from '@/mixins/form-editable';

export default {
  name: 'CheckoutCard',

  components: { CheckoutInfo, Checkout },

  mixins: [formEditable],

  props: {
    person: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    isValid: true,
    form: { personal_profile: {}, inhabitant: {}, user: {} },
  }),

  watch: {
    isValid() {
      this.$emit('isValid', this.isValid);
    },
  },

  methods: {
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
