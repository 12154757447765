<template>
  <v-card tile>
    <v-card-title class="font-weight-regular">
      {{ $t('SHARED_DOCUMENTS.ITEMS') }}
    </v-card-title>

    <v-divider />

    <v-card-text>
      <v-sheet class="pa-4">
        <shared-documents-dir-list :dirs="dirs" @show="show" />
      </v-sheet>

      <v-dialog
        v-if="dialog"
        v-model="dialog"
        persistent
        width="unset"
        max-width="1000"
      >
        <component
          :is="component.name"
          v-bind="component.data"
          @cancel="cancelDialog"
        />
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import i18nRefreshData from '@/mixins/i18nRefreshData';
import ViewSharedDocuments from '@/modules/persons/components/SharedDocuments/ViewSharedDocuments.vue';
import SharedDocumentsDirList from '@/modules/persons/components/SharedDocuments/SharedDocumentsDirList.vue';
import { PersonsService } from '@/services/persons.service';

export default {
  name: 'SharedDocumentsCard',

  components: {
    ViewSharedDocuments,
    SharedDocumentsDirList,
  },

  mixins: [i18nRefreshData],

  props: {
    personId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      dirs: [],
      dialog: false,
      component: null,
    };
  },

  watch: {
    options: {
      deep: true,
      immediate: true,
      handler() {
        this.refreshData();
      },
    },
  },

  methods: {
    refreshData() {
      this.getSharedDirs();
    },

    getSharedDirs() {
      return PersonsService.getSharedDocumentsDirs({
        personId: this.personId,
      }).then(({ data: { data } }) => {
        this.dirs = data;
      });
    },

    show(dir) {
      this.dialog = true;
      this.component = {
        name: 'ViewSharedDocuments',
        data: {
          dir,
          dirId: dir.id,
          personId: this.personId,
        },
      };
    },

    cancelDialog() {
      this.dialog = false;
      this.component = null;
    },
  },
};
</script>
