<template>
  <v-card tile class="mt-6">
    <v-card-title class="font-weight-regular">
      {{ $t('USERS.ACCESS_TO_THE_APP') }}

      <!--      <v-icon-->
      <!--        v-if="!editMode"-->
      <!--        class="ml-2"-->
      <!--        color="indigo"-->
      <!--        @click="toggleEditMode"-->
      <!--        >mdi-square-edit-outline</v-icon-->
      <!--      >-->
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <user-information-form
          v-model="localUser"
          :readonly="!editMode"
          :errors="errors.getAll()"
        />
      </v-form>
    </v-card-text>

    <v-card-actions v-if="editMode">
      <v-btn outlined class="mr-5" depressed color="grey" large @click="cancel">
        {{ $t('BUTTON.CANCEL') }}
      </v-btn>
      <v-btn
        depressed
        type="submit"
        :disabled="!valid"
        large
        color="success"
        class="mr-5"
        @click.prevent="update"
      >
        {{ $t('BUTTON.UPDATE') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { isEqual } from 'lodash';
import errors from '@/mixins/errors';
import UserInformationForm from '../components/forms/UserInformationForm.vue';

export default {
  name: 'AccessAppCard',

  model: {
    prop: 'user',
  },

  mixins: [errors],

  components: {
    UserInformationForm,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    valid: false,
    editMode: false,
    localUser: {},
  }),

  watch: {
    user: {
      deep: true,
      immediate: true,
      handler: function (user) {
        if (isEqual(this.user, this.localUser)) {
          return;
        }

        this.localUser = {
          ...this.localUser,
          ...user,
        };
      },
    },
  },

  methods: {
    update() {
      // TODO: update
    },
    toggleEditMode() {
      // this.editMode = !this.editMode;
    },
    cancel() {
      this.$nextTick(() => {
        this.localUser = { ...this.user };
        this.toggleEditMode();
        this.errors.clear();
      });
    },
  },
};
</script>
