<template>
  <v-text-field
    v-model="password"
    v-bind="inputAttributes"
    @click:prepend="generate"
    @click:append="generate"
    v-on="$listeners"
  ></v-text-field>
</template>

<script>
export default {
  name: 'TextFieldPasswordGenerate',

  props: {
    value: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '8',
    },
    characters: {
      type: String,
      default: 'a-z,A-Z,0-9,#',
    },
    auto: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      replace: this.value,
    };
  },

  computed: {
    password: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    inputAttributes() {
      if (
        // eslint-disable-next-line no-prototype-builtins
        this.$attrs.hasOwnProperty('prepend-icon') ||
        // eslint-disable-next-line no-prototype-builtins
        this.$attrs.hasOwnProperty('append-icon')
      ) {
        return this.$attrs;
      }

      // default icon
      return {
        ...this.$attrs,
        'append-icon': 'mdi-lock-reset',
      };
    },
  },

  mounted() {
    if (this.auto) {
      this.generate();
    }
  },

  methods: {
    generate() {
      if (this.$attrs.readonly) {
        return;
      }

      let charactersArray = this.characters.split(',');
      let CharacterSet = '';
      let password = '';

      if (charactersArray.indexOf('a-z') >= 0) {
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
      }
      if (charactersArray.indexOf('A-Z') >= 0) {
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      }
      if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789';
      }
      if (charactersArray.indexOf('#') >= 0) {
        CharacterSet += '![]{}()%&*$#^<>~@|';
      }

      for (let i = 0; i < this.size; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.password = password;
    },
  },
};
</script>
