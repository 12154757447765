<template>
  <v-row dense>
    <v-col cols="12" md="4">
      <v-text-field
        v-model="form.username"
        name="username"
        :label="`${$t('USERS.USERNAME')} *`"
        :readonly="readonly"
        :rules="getRulesByName('username')"
        :error-messages="errors.username"
        @input="updateValue('username', $event)"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model="form.email"
        name="email"
        :label="`${$t('USERS.EMAIL')} *`"
        :readonly="readonly"
        :rules="getRulesByName('email')"
        :error-messages="errors.email"
        @input="updateValue('email', $event)"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <text-field-password-generate
        v-model="form.password"
        name="password"
        :label="$t('USERS.PASSWORD')"
        :counter="8"
        :readonly="readonly"
        :rules="getRulesByName('password')"
        :error-messages="errors.password"
        @input="updateValue('password', $event)"
      ></text-field-password-generate>
    </v-col>
  </v-row>
</template>

<script>
import formEditable from '@/mixins/form-editable';
import TextFieldPasswordGenerate from '@/components/TextFieldPasswordGenerate.vue';
import { notEmpty, maxLength, isEmail } from '@/rules';

export default {
  name: 'UserInformationForm',

  components: {
    TextFieldPasswordGenerate,
  },

  mixins: [formEditable],

  data() {
    return {
      valid: false,
      form: {
        username: '',
        email: '',
        password: '',
      },
      rules: {
        username: [notEmpty],
        email: [notEmpty, isEmail],
        password: [maxLength(8)],
      },
    };
  },
};
</script>
