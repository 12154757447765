<template>
  <div>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="dirs"
      :server-items-length="meta.total"
      :footer-props="footerProps"
      :options="options"
      :page="page"
      :items-per-page="itemsPerPage"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :multi-sort="multiSort"
      v-on="listeners"
    >
      <template v-slot:item.action="{ item }">
        <div class="text-center">
          <v-list-item @click="$emit('show', item)">
            <v-list-item-title>
              <v-icon class="mr-1" small>mdi-eye</v-icon>
              {{ $t('ITEM.VIEW') }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { isEqual } from 'lodash';

export default {
  name: 'SharedDocumentsDirList',

  props: {
    dirs: {
      type: Array,
      required: true,
    },
    meta: {
      type: Object,
      default: () => ({
        total: -1,
      }),
    },
    options: {
      type: Object,
      default: () => {},
    },
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    sortBy: {
      type: [String, Array],
      default: 'id',
    },
    sortDesc: {
      type: [Boolean, Array],
      default: false,
    },
    multiSort: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    footerProps: {
      itemsPerPageOptions: [10, 50, 100],
    },
  }),

  computed: {
    listeners: function () {
      return Object.assign({}, this.$listeners, {
        'update:options': this.updateOptions,
      });
    },

    headers() {
      return [
        {
          text: this.$t('ITEM.ID'),
          align: 'left',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('SHARED_DOCUMENTS.FOLDER'),
          value: 'name',
        },
        {
          text: this.$t('SHARED_DOCUMENTS.FILES'),
          value: 'document_count',
        },
        {
          text: this.$t('DATA_TABLE.ACTIONS'),
          value: 'action',
          align: 'center',
        },
      ];
    },
  },

  methods: {
    updateOptions(options) {
      if (isEqual(options, this.options)) {
        return;
      }

      this.$emit('update:options', options);
    },
  },
};
</script>
