<template>
  <v-card :disabled="disabled" tile>
    <v-card-title class="font-weight-regular">
      {{ $t('APARTMENTS.ITEMS') }}
      <v-spacer></v-spacer>

      <v-card-subtitle v-if="disabled" class="black--text pa-0">{{
        $t('APARTMENTS.NEED_ADD_ACCESS')
      }}</v-card-subtitle>
      <v-btn
        class="mr-2"
        outlined
        v-if="!disabled"
        color="indigo"
        @click.stop="createInhabitantChange"
      >
        {{ $t('APARTMENTS.CHECKOUT') }}
      </v-btn>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <inhabitant-form v-model="form" readonly />
        <inhabitant-info v-model="form" />
      </v-form>

      <v-dialog
        v-if="dialogCreateRequest && form"
        v-model="dialogCreateRequest"
        persistent
        max-width="900px"
      >
        <checkout
          v-if="form"
          v-model="form"
          :person="person"
          @cancel="cancelCheckout"
          @isValid="isValid"
          :errors="errors.getAll()"
          @update-prop="clearErrorByName($event)"
        >
          <template v-slot:buttons>
            <v-btn
              large
              outlined
              depressed
              color="grey"
              @click="cancelCheckout"
              >{{ $t('BUTTON.CANCEL') }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              large
              depressed
              color="primary"
              @click="checkoutInhabitant"
              :disabled="!valid"
            >
              {{ $t('BUTTON.CREATE') }}
            </v-btn>
          </template>
        </checkout>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { isEqual } from 'lodash';
import InhabitantForm from '@/modules/inhabitant_changes/components/forms/InhabitantForm.vue';
import Checkout from '@/modules/persons/components/CheckoutCard.vue';
import InhabitantInfo from '@/modules/persons/components/InhabitantInfo';
import errors from '@/mixins/errors';
import { InhabitantChangesService } from '@/services/inhabitant-changes.service';

export default {
  name: 'InhabitantCard',

  model: {
    prop: 'inhabitant',
  },

  mixins: [errors],

  components: {
    InhabitantInfo,
    InhabitantForm,
    Checkout,
  },

  props: {
    inhabitant: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    person: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    valid: undefined,
    form: {},
    dialogCreateRequest: false,
  }),

  watch: {
    inhabitant: {
      deep: true,
      immediate: true,
      handler: function (inhabitant) {
        if (isEqual(this.inhabitant, this.form)) {
          return;
        }

        this.form = {
          ...this.form,
          ...inhabitant,
        };
      },
    },
  },

  methods: {
    isValid(val) {
      this.valid = val;
    },

    createInhabitantChange() {
      this.form = {};
      this.dialogCreateRequest = true;
      this.valid = true;
    },

    checkoutInhabitant() {
      InhabitantChangesService.checkout({
        inhabitant: { ...this.form, inhabitant_id: this.form.id },
      })
        .then((response) => {
          this.$emit('update');
          this.cancelCheckout();
          return response;
        })
        .catch((response) => {
          this.handleErrorsByResponse(response);
        });
    },

    cancelCheckout() {
      this.dialogCreateRequest = false;
      this.errors.clear();
      this.form = {
        ...this.form,
        ...this.inhabitant,
      };
    },
  },
};
</script>
