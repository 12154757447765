<template>
  <v-card tile flat color="grey lighten-4 mb-6" elevation="0">
    <v-row>
      <v-col cols="3">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="d-flex">
              <strong>
                {{ $t('BUILDING_COMPLEXES.BUSINESS_CUSTOMER') }}
              </strong>
            </v-list-item-title>
            <v-list-item-subtitle class="d-flex text-wrap text--primary"
              >{{ businessCustomer || '' }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="3">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{ $t('BUILDING_COMPLEXES.MANAGEMENT_COMPANY') }}
              </strong>
            </v-list-item-title>
            <v-list-item-subtitle class="d-flex text-wrap text--primary"
              >{{ managementCompany || '' }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="3">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>
              <strong>
                {{ $t('BUILDING_COMPLEXES.CONCIERGE') }}
              </strong>
            </v-list-item-title>
            <v-list-item-subtitle class="d-flex text-wrap text--primary">
              {{ concierge || '' }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import formEditable from '@/mixins/form-editable';

export default {
  name: 'InhabitantInfo',

  mixins: [formEditable],

  computed: {
    getBuildingComplex() {
      return this.form.apartment.building.building_complex;
    },
    businessCustomer() {
      return this.getBuildingComplex.business_customer.name;
    },
    managementCompany() {
      return this.getBuildingComplex.management_company.name;
    },
    concierge() {
      let concierge = this.getBuildingComplex.concierge.person;
      return concierge &&
        (concierge.first_name.length || concierge.last_name.length)
        ? concierge.first_name + ' ' + concierge.last_name
        : '';
    },
  },

  data: () => ({
    form: {
      apartment: {
        building: {
          building_complex: {
            business_customer: {},
            concierge: {},
            management_company: {},
          },
        },
      },
    },
  }),
};
</script>
